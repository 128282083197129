<template>
  <ClientEditTemplate>

    <div class="row">
      <div class="col-8">
        <FveSelectAjaxV2Trainer
          label="Добавить тренера"
          v-model="trainer"
        />
      </div>
      <div class="col-4">
        <div class="btn-gp" style="padding-top: 24px;">
          <b-button size="lg" variant="danger" @click="addTrainer">Добавить</b-button>
          <!--<b-button size="lg" variant="link" @click="$emit('cancel')">Отмена</b-button>-->
        </div>
      </div>
    </div>

    <div class="row">
      <template v-for="(item, index) in clientTrainerList">
        <div class="col-4 mb-md" :key="index">
          <CardTrainer
            :img="item.avatar.getSrc()"
            :name="item.last_name + ' ' + item.first_name+ ' ' + item.middle_name"
            :tags="item.tags.map((tagObj) => tagObj.tag)"

            @remove="remove(item, index)"
          />
        </div>
      </template>
    </div>

  </ClientEditTemplate>
</template>

<script>

import ClientEditTemplate from "@page/Client/_component/ClientEditTemplate";
import CardTrainer from "@component/Module/CardTrainer";

import FveSelectAjaxV2Trainer from "@widgetFormValidate/src/Element/Custom/FveSelectAjaxV2Trainer";

export default {
  name: "ClientEditTrainer",
  components: {
    ClientEditTemplate,
    CardTrainer,
    FveSelectAjaxV2Trainer,
  },
  props: {
    clientId: [String, Number]
  },

  data(){
    return this.getDefaultData();
  },
  methods:{
    getDefaultData() {
      return {
        trainer: null,
        clientTrainerList: [],
      };
    },
    load(){
      RequestManager.Client.getClientById({
        id: this.clientId
      }).then((client) => {

        let promiseArr = [];
        for(let i = 0; i < client.trainers.length; i++ ){
          let promise = RequestManager.Trainer.getTrainerById({id: client.trainers[i]});
          promiseArr.push(promise);
        }

        if(promiseArr.length > 0) {
          Promise.all(promiseArr).then(values => {
            this.clientTrainerList = values;
          });
        }

      });
    },
    reload(){
      Object.assign(this, this.getDefaultData());
      this.load();
    },


    addTrainer(){
      RequestManager.Client.addClientTrainer({
        clientId: this.clientId,
        trainerId: this.trainer ? this.trainer.id : null,
      }).then(this.reload);
    },
    remove(trainer){
      const options = {title: 'Удалить тренера?', cancelLabel: 'Нет', okLabel: 'Да'};
      this.$dialogs.confirm('Удалить тренера?', options)
        .then((res) => { // {ok: true|false|undefined}
          if(res && res.ok) {
            RequestManager.Client.deleteClientTrainer({
              clientId: this.clientId,
              trainerId: trainer ? trainer.id : null,
            }).then(this.reload);
          }
        });
    }
  },
  mounted() {
    this.load();
  }
};
</script>

<style lang="scss" scoped>

</style>
